export interface NotificationModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  /** id пользователя */
  customerId: string;
  /** Код уведомления */
  code: NotificationCode;
  /** Тип уведомления. email, push, sms */
  type: NotificationType;
  /** Статус доставки */
  status: NotificationStatus;
  /** Было ли уведомление просмотрено пользователем */
  viewed: boolean;
  /** Куда рассылалось уведомление */
  targets: string[];
  /** Заголовок уведомления */
  title: string | null;
  /** Текст уведомления */
  message: string | null;
  /** HTML Текст уведомления */
  textHtml: string | null;
  /** Параметры уведомления (для использования в шаблоне) */
  payload: object | null;
  /** Дополнительные параметры отправки уведомления */
  additionalOptions: object | null;
  /** Метаданные уведомления, переданные сервисом источником */
  metadata: Record<string, string> | null;
}

export enum NotificationType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS',
}

export enum NotificationStatus {
  New = 'NEW',
  Sent = 'SENT',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
}

export enum NotificationCode {
  KycApproved = 'KYC_APPROVED',
  KycReject = 'KYC_REJECT',
  KycRetry = 'KYC_RETRY',
  RentBeforeOneDayStart = 'RENT_BEFORE_ONE_DAY_START',
  RentBeforeOneDayEnd = 'RENT_BEFORE_ONE_DAY_END',
  Test = 'TEST',
}
