/** контроллеры в сервисе crm */
export enum CrmControllersEnum {
  Auth = 'auth',
  User = 'user',
  Customer = 'customer',
  Vehicle = 'vehicle',
  VehicleInspection = 'vehicle-inspection',
  Storage = 'storage',
  Rent = 'rent',
  Tariff = 'tariff',
  Device = 'device',
  Catalog = 'catalog',
  Maintenance = 'maintenance',
  CheckList = 'check-list',
  Scoring = 'scoring',
  Notification = 'notification',
}
