import { NotificationCode } from './notification.model';

export interface PushHistoryModel {
  id: string;
  viewed: boolean;
  code: NotificationCode;
  title: string | null;
  message: string | null;
  textHtml: string | null;
  payload: object | null;
  createdAt: Date;
}
