import { EngineType, TariffDurationUnit } from '../../../mobile-bff';
import { VehicleDriveType, VehicleTransmissionType } from '../../../mobile-bff/vehicle/dto';

export interface CrmGetCatalogResponseDto {
  id: string;
  vehicleId: string;
  renderPath: string | null;
  model: string;
  brand: string;
  year: number;
  tariffId: string;
  price: number;
  isBooked: boolean;
  engineType: EngineType | null;
  enginePower: number | null;
  duration: number;
  durationUnit: TariffDurationUnit;
  transmissionType: VehicleTransmissionType | null;
  driveType: VehicleDriveType | null;
}
