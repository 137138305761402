import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { MobileBffControllerEnum } from '../../mobile-bff.controller.enum';
import { AppointmentModel } from './models';

import { MobileBffCreateInspectionAppointmentRequest } from './interfaces';

/**
 * ### Создание записи на ТО
 * basePath: /mobile-bff
 * controller: /vehicle-inspection
 * path: 'appointment'
 * method: POST
 * fullPath: /mobile-bff/vehicle-inspection/appointment
 */
export namespace MobileBffCreateInspectionAppointmentEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.VehicleInspection;
  export const endPointPath = 'appointment';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: бронирование
   */
  export type ResponseData = AppointmentModel;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = MobileBffCreateInspectionAppointmentRequest;

  export enum ErrorCodes {}
}
