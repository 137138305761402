import {
  VehicleEquipmentInterface,
  VehicleGenerationInterface,
  VehicleImage,
  VehicleModificationInterface,
} from './get-vehicle.response.dto';

export interface GetVehicleFromCatalogInterface {
  /** id машины */
  id: string;
  /** vin код авто */
  vin: string;
  /** Гос номер*/
  license: string | null;
  /** марка */
  brand: string;
  /** модель */
  model: string;
  /** данные о модификации */
  modification: VehicleModificationInterface;
  /** данные о комплектации */
  equipment: VehicleEquipmentInterface;
  /** Данные о поколении */
  generation: VehicleGenerationInterface;
  /** Изображения авто */
  images: VehicleImage[];
}
