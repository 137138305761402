import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { provideLoginRestriction } from "./login-restriction.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [provideLoginRestriction()],
})
export class LoginRestrictionModule {}
