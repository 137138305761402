export interface TariffModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly ident: string;
  name: string;
  duration: number;
  durationDays: number;
  durationUnit: TariffDurationUnit;
  durationType: TariffDurationType;
  minDuration: number | null;
  maxDuration: number | null;
  minStartPeriod: number | null; // минимальный период до начала бронирования (дни)
  maxStartPeriod: number | null; // максимальный период до начала бронирования (дни)
  mileageSessionLimit: number | null;
  mileageTotalLimit: number | null;
  mileageCost: number | null;
  hoursSessionLimit: number | null;
  hoursTotalLimit: number | null;
  hoursCost: number | null;
  description: string | null;
}

export const DAYS_IN_MONTH = 30;

export enum TariffDurationUnit {
  Day = 'day',
  Month = 'month',
}

export enum TariffDurationType {
  Open = 'OPEN', //с возможностью выбирать продолжительность
  Fixed = 'FIXED', //c фиксированной продолжительностью
}
