import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { AuthProfileState } from '@app/core/auth/states/auth-profile.state';
import { RESTRICTED_LOGIN } from '@app/core/login-restriction/consts/restricted-login.const';
import { SidenavItem } from '@app/core/sidenav/sidenav-item.model';
import { SIDENAV_MENU_ITEMS } from '@app/core/sidenav/sidenav-menu-items.const';
import { SetSidenavItemsAction } from '@app/core/sidenav/store/sidenav.actions';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoginRestrictionService {
  isRestrictedLogin$ = this.store.select(AuthProfileState.profile).pipe(
    map((profile) => profile?.login),
    filter((login): login is string => !!login),
    distinctUntilChanged(),
    map((login) => login === RESTRICTED_LOGIN),
    shareReplay(),
  );

  constructor(private readonly store: Store) {}

  public init(): void {
    this.store
      .select(AuthProfileState.profile)
      .pipe(
        map((profile) => profile?.login),
        filter((login): login is string => !!login),
        distinctUntilChanged(),
        map((login) => this.getSidenavByLogin(login)),
        tap((sidenavItems) => this.store.dispatch(new SetSidenavItemsAction(sidenavItems))),
      )
      .subscribe();
  }

  protected getSidenavByLogin(login: string): SidenavItem[] {
    if (login === RESTRICTED_LOGIN) {
      return [...SIDENAV_MENU_ITEMS].filter((i) => i.route === '/vehicles');
    } else {
      return [...SIDENAV_MENU_ITEMS];
    }
  }
}

export function provideLoginRestriction(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: (service: LoginRestrictionService) => () => service.init(),
    deps: [LoginRestrictionService],
    multi: true,
  };
}
