import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { GetVehicleFromCatalogInterface } from './dto';

/**
 * ### Получение машины из каталога
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: ':vehicleId/from-catalog'
 * method: GET
 * fullPath: /mobile-bff/vehicle/:vin/from-catalog
 */
export namespace MobileBffGetCarFromCatalogEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = ':vehicleId/from-catalog';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: авто из каталога
   */
  export type ResponseData = GetVehicleFromCatalogInterface;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * @param vehicleId id машины
   * */
  export type RequestPathParams = {
    vehicleId: string;
  };

  /** Параметры запроса
   * @param tariffId id тарифа
   * */
  export type RequestQueryParams = {
    tariffId: string;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
