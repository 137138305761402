import { CheckListStageEntity } from './check-list-stage.entity';

export enum CheckListType {
  PreRent = 'pre_rent',
  PostRent = 'post_rent',
}

export type CheckListEntity = {
  id: string;
  /**
   * Название чеклиста
   */
  name: string;
  /**
   * Проект чеклиста
   */
  project: string;
  /**
   * Тип чеклиста
   */
  type: CheckListType;
  /**
   * Этапы чеклиста
   */
  stages: CheckListStageEntity[];
};
