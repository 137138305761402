export enum ReportType {
  SelfiePassportCompare = 'selfie_passport_compare',
  PassportMainRecognition = 'passport_main_recognition',
  PassportCheck = 'passport_check',
  PersonCheckWanted = 'person_check_wanted',
  DriverLicenseRecognition = 'driver_license_recognition',
  DriverLicenseCheck = 'driver_license_check',
}

export enum ScoringStatus {
  New = 'NEW',
  Processing = 'PROCESSING',
  Retry = 'RETRY',
  Done = 'DONE',
  Reject = 'REJECT',
  Error = 'ERROR',
}

export enum ReportStatus {
  New = 'NEW',
  Waiting = 'WAITING',
  Retry = 'RETRY',
  Done = 'DONE',
  Reject = 'REJECT',
  Error = 'ERROR',
}

export enum SelfiePassportCompareStatus {
  FaceNotFound = 'FACE_NOT_FOUND',
  Match = 'MATCH',
  DontMatch = 'DONT_MATCH',
  LowImageQuality = 'LOW_IMAGE_QUALITY',
  None = 'NONE',
}

export type SelfiePassportCompareReportResult = {
  status: SelfiePassportCompareStatus;
  statusDesc: string;
  matchPercentage: number;
};

export enum RecognitionStatus {
  Ok = 'OK',
  DocumentTypeIsNotAllowed = 'DOCUMENT_TYPE_IS_NOT_ALLOWED',
  LowImageQuality = 'LOW_IMAGE_QUALITY',
  DocumentTypeNotMatch = 'DOCUMENT_TYPE_NOT_MATCH',
  ValidationFail = 'VALIDATION_FAIL',
  PayloadTooLarge = 'PAYLOAD_TOO_LARGE',
  DownloadFail = 'DOWNLOAD_FAIL',
  ClassifyFail = 'CLASSIFY_FAIL',
  RecognitionFail = 'RECOGNITION_FAIL',
  None = 'NONE',
}

export type PassportMainRecognitionReportResult = {
  status?: RecognitionStatus;
  surname?: string;
  firstName?: string;
  otherNames?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  series?: string;
  number?: string;
  issuingAuthority?: string;
  dateOfIssue?: string;
  subdivisionCode?: string;
};

export enum BeOnTheListType {
  IsOnTheList = 'IS_ON_THE_LIST',
  WasOnTheList = 'WAS_ON_THE_LIST',
  IsNotOnTheList = 'IS_NOT_ON_THE_LIST',
}

export type PassportCheckReportResult = {
  expired?: boolean;
  number?: string;
  series?: string;
  details?: string;
  dateFirstAppearance?: string;
  foundTerrorism?: boolean;
  beOnTheListTerrorism?: BeOnTheListType;
};

export type PersonCheckWantedReportResult = {
  wanted?: boolean;
};

export type DriverLicenseRecognitionReportResult = {
  status?: RecognitionStatus;
  surname?: string;
  name?: string;
  patronymic?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  number?: string;
  dateFrom?: string;
  dateEnd?: string;
  issuer?: string;
  placeOfIssue?: string;
  categories?: string[];
};

export type DriverLicenseCheckReportResult = {
  isValidDocument: boolean;
  isDocumentFinished: boolean;
  experience: string;
  decisions: {
    state: number;
  }[];
};

export type ReportResult =
  | SelfiePassportCompareReportResult
  | PassportMainRecognitionReportResult
  | PassportCheckReportResult
  | PersonCheckWantedReportResult
  | DriverLicenseRecognitionReportResult
  | DriverLicenseCheckReportResult;

export interface CrmReportEntity {
  id: string;
  reportType: ReportType;
  scoringId: CrmScoringEntity['id'];
  createdAt: Date;
  requestUid: string | null;
  request: Record<string, unknown> | null;
  status: ReportStatus;
  sentAt: Date | null;
  result: ReportResult | null;
  respondedAt: Date | null;
}

export interface CrmScoringEntity {
  id: string;
  customerId: string;
  createdAt: Date;
  reports: CrmReportEntity[];
  status: ScoringStatus;
}
