import { PaymentModel } from './payment.model';

export interface OrderModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  rentId: string;
  sessionId: string | null;
  customerId: string;
  type: OrderType;
  status: OrderStatus;
  totalPrice: number;
  isFine: boolean; // начисление является штрафом
  isAdvance: boolean; // true - оплата в 2 этапа, false - оплата в 1 этап
  description: string | null;

  paidAt: Date | null;
  paidDue: Date | null;

  payments: PaymentModel[]; //relation
}

export enum OrderType {
  Reservation = 'RESERVATION',
  Session = 'SESSION',
  FineGIBDD = 'GIBDD_FINE',
  CancellationFine = 'CANCELLATION_FINE',
  OverspendingFine = 'OVERSPENDING_FINE',
}

export enum OrderStatus {
  New = 'NEW',
  Advance = 'ADVANCE',
  Paid = 'PAID',
  Failed = 'FAILED',
  Expired = 'EXPIRED',
}
