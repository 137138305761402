import { CustomerModel } from './customer.model';

export interface DriverModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  /** ID аренды */
  rentId: string;
  /** ID пользователя-водителя */
  driverId: string;
  /** Номер телефона водителя */
  phone: string;
  /** Никнейм вводителя */
  nickname: string | null;
  /** Дата предоставления доступа */
  accessStartDate: Date;
  /** Дата окончания доступа */
  accessEndDate: Date | null;
  /** Профиль водителя */
  profile: CustomerModel | null;
}
