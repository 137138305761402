import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CommandType } from '../../mobile-bff';
import { CrmVehicleGetLastPacketResponseDto } from './dtos';

/**
 * ### Отправка команды
 * basePath: /crm
 * controller: /vehicle
 * path: ':vin/command'
 * method: POST
 * fullPath: /crm/vehicle/:vin/command
 */
export namespace CrmVehicleSendCommandEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = ':vin/command';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: ответ от телематики
   */
  export type ResponseData = CrmVehicleGetLastPacketResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * @param vin вин авто
   * */
  export type RequestPathParams = {
    vin: string;
  };

  /** Параметры запроса
   * @param command команда
   * */
  export type RequestQueryParams = {
    command: CommandType;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
