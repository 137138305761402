import { EDeviceType } from '../../../mobile-bff/vehicle/dto';

import { CrmVehicleImage } from './crm-vehicle-image.interface';

export interface CrmVehicleInterface {
  /** id машины */
  id: string;
  /** vin код авто */
  vin: string;
  /** марка */
  brand: string;
  /** модель */
  model: string;
  /** статус авто */
  vehicleStatus: CrmVehicleStatus;
  /** статус аренды */
  rentStatus: CrmRentStatus;
  /** поколение */
  generation: string | null;
  /** Гос номер */
  license: string | null;
  /** ПТС */
  vehiclePassport: string | null;
  /** у авто есть электронный птс */
  electronicPassport: boolean;
  /** год выпуска */
  issueYear: string | null;
  /** Страна производства */
  manufactureCountry: string | null;
  /** Описание */
  description: string | null;
  /** Тип кузова*/
  bodyType: string | null;
  /** количество дверей */
  doorCount: number | null;
  /** Количество сидений */
  seats: number | null;
  /** Расположение руля*/
  steeringWheelPlacement: string | null;

  /** описание цвета */
  color: CrmVehicleColor;
  /** данные о модификации */
  modification: CrmVehicleModification;
  /** данные о комплектации */
  equipment: CrmVehicleEquipment;
  /** размеры */
  dimensions: CrmVehicleDimensions;
  /** Массив блоков телематики */
  blocks: CrmVehicleBlock[]; // relation
  /** Изображения авто */
  images: CrmVehicleImage[]; // relation

  /** Дата создания */
  createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
}

export enum CrmVehicleStatus {
  /** Новая */
  New = 'NEW',
  /** Эксплуатация */
  Exploitation = 'EXPLOITATION',
  /** Выведена из эксплуатации  */
  OutOfService = 'OUT_OF_SERVICE',
  /** Продана */
  Sold = 'SOLD',
}

export enum CrmEngineType {
  /** Электро */
  Electronic = 'ELECTRONIC',
  /** Топливный */
  Fuel = 'FUEL',
}

export enum CrmRentStatus {
  /** Не доступна для аренды */
  Idle = 'IDLE',
  /** В каталоге */
  InCatalog = 'IN_CATALOG',
  /** Подготовка */
  Prepare = 'PREPARE',
  /** В аренде */
  InRent = 'IN_RENT',
  /** Проверка */
  Checking = 'CHECKING',
}

export interface CrmVehicleColor {
  color: string | null;
  colorCode: string | null;
}

export interface CrmVehicleModification {
  /** Название */
  name: string | null;
  /** Код */
  modificationCode: string | null;
  /** Мощность */
  power: number | null;
  /** Ёмкость батареи или бака */
  batteryOrTankCapacity: number | null;
  /** Запас хода */
  powerReserve: number | null;
  /** Максимальная скорость */
  maxSpeed: number | null;
  /** Время разгона до 100 кмч */
  accelerationTime100: number | null;
  /** Время разгона до 60 кмч */
  accelerationTime60: number | null;
  /** Максимальная крутящий момент на оборотах */
  maxTorqueAtRpm: number | null;
  /** Тип передних тормозов*/
  frontBrakes: string | null;
  /** Тип задних тормозов*/
  backBrakes: string | null;
  /** тип подвески */
  suspensionType: string | null;
  /** тип двигателя */
  engineType: CrmEngineType | null;
  /** тип топлива */
  fuelType: string | null;
  /** мощность двигателя KWt/h */
  enginePower: number | null;
  /** тип трансмиссии */
  transmissionType: CrmVehicleTransmissionType | null;
  /** тип привода */
  driveType: CrmVehicleDriveType | null;
  /** обьём двигателя */
  engineCapacity: number | null;
}

export enum CrmVehicleDriveType {
  AWD = 'AWD', // all-wheel-drive
  FWD = 'FWD', // front wheel drive
  RWD = 'RWD', // rear wheel drive
}

export enum CrmVehicleTransmissionType {
  Manual = 'MANUAL',
  Automatic = 'AUTOMATIC',
}

export interface CrmVehicleEquipment {
  equipmentCode: string | null;
  name: string | null;
}

export interface CrmVehicleDimensions {
  /** Высота */
  height: number | null;
  /** Ширина */
  width: number | null;
  /** Длина */
  length: number | null;
  /** Колесная база */
  wheelbase: number | null;
  /** Клиренс */
  clearance: number | null;
  /** Объем багажника */
  trunk: number | null;
}

export interface CrmVehicleBlock {
  /** Внутренний идентификатор устройства */
  id: string;
  /** Серийный номер блока */
  deviceId: string;
  /** Блок является приоритетным */
  priority: boolean;
  /** Блок является установленным */
  installed: boolean;
  /** Тип блока */
  type: EDeviceType;
  // /** Флаг активности блока */
  // active: boolean;
  /** Модель блока */
  model: 'LITE' | 'PRO' | string | null;
  /** Дата первой активации */
  firstActivationDate: Date | null;
  /** Дата последней активации */
  activationDate: Date | null;
  /** Дата последней деактивации */
  deactivationDate: Date | null;
}
