import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';
import { CrmVehicleInsuranceFindResponseDto } from '../dtos';
import { InsuranceType } from '../models';

/**
 * ### Получение списка страховок с пагинацией
 * basePath: /crm
 * controller: /vehicle
 * path: ':vin/insurance'
 * method: GET
 * fullPath: /crm/vehicle/:vin/insurance
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleInsuranceFindEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = ':vin/insurance';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<CrmVehicleInsuranceFindResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    vin: string;
  };

  /** Параметры запроса */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: 'id' | 'startDate' | 'endDate' | 'type';
    sortDirection?: 'asc' | 'desc';

    vehicleId?: string;
    type?: InsuranceType;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
