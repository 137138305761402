export interface PaymentModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  externalPaymentId: string;
  orderId: string;
  customerId: string;
  url: string | null;
  successUrl: string | null;
  failedUrl: string | null;
  type: PaymentType;
  status: PaymentStatus;
}

export enum PaymentType {
  Advance = 'ADVANCE', // авансовый платёж (холдирование средств)
  AdvanceAccept = 'ADVANCE_ACCEPT', // списание авансового платежа
  AdvanceReverse = 'ADVANCE_REVERSE', // возврат авансового платежа
  Payment = 'PAYMENT', // оплата без холдирования средств
  Refund = 'REFUND', // возврат средств
}

export enum PaymentStatus {
  New = 'NEW',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Expired = 'EXPIRED',
}
