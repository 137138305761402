export interface SimData {
  /** sim id */
  id: string;
  /** Номер телефона */
  phoneNumber?: string | null;
  /** Приоритет симки при отправке смс, при одинаковом приоритете - отправляется случайно, наиболее приоритетные - 1, положительное целое число */
  priority?: number;
}

export interface SimDto extends SimData {
  /** id устройства */
  deviceId: string | null;
}

export interface SimEntity extends SimDto {
  /** Целостность */
  integrity: boolean;
  /** Дата создания */
  createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
}
