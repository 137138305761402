export interface AddCarToCatalogResponseDto {
  /** Идентификатор автомобиля */
  vehicleId: string;
  /** Идентификатор тарифа */
  tariffId: string;
  /** Стоимость бронирования */
  price: number;
  /** Идентификатор пользователя, выполняющего бронирование */
  reservedByCustomerId: string | null;
  /** Дата, до которой автомобиль забронирован */
  reservedUntil: Date | null;
  /** Дата добавления в каталог */
  createdAt: Date;
  /** Дата последнего обновления в каталоге */
  updatedAt: Date;
}
