import { CrmEngineType } from '../../vehicle';

export interface CrmPostCatalogRequestDto {
  vehicleId: string;
  renderPath: string | null;
  model: string;
  brand: string;
  year: number;
  tariffId: string;
  price: number;
  engineType: CrmEngineType;
  isMain?: boolean;
}
