export enum CrmCustomerStatus {
  New = 'NEW',
  ActiveScoring = 'ACTIVE_SCORING',
  Retry = 'RETRY',
  Rejected = 'REJECTED',
  WaitForOperator = 'WAIT_FOR_OPERATOR',
  Approved = 'APPROVED',
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED',
}

export interface CrmCustomerDrivingLicense {
  photos: string[];
  firstName?: string;
  lastName?: string;
  middleName?: string;
  birthDate?: string;
  issued?: string;
  issueDate?: string;
  expireDate?: string;
  experience?: string;
  dLicenseNumber?: string;
  dLicenseSerial?: string;
  kbm?: number;
  kbmWarn?: string;
  firstNameEn?: string;
  middleNameEn?: string;
  lastNameEn?: string;
  categories?: string;
  validDlMoreYears?: number;
  dlExperienceYears?: number;
  country?: string;
}

export interface CrmCustomerPassport {
  photos: string[];
  firstName?: string;
  lastName?: string;
  middleName?: string;
  gender?: string;
  birthDate?: string;
  citizenship?: string;
  placeDate?: string;
  passportIssued?: string;
  passportIssueDate?: string;
  passportDepartmentCode?: string;
  passportNumber?: string;
  passportSerial?: string;
  firstNameEn?: string;
  middleNameEn?: string;
  lastNameEn?: string;
  age?: number;
  dlRiskScore?: number;
  lastFaceMatchConfidence?: number;
}

export interface CrmCustomerScoring {
  scoringId: string;
  updatedAt?: Date;
  currentReportType?: string | null;
  operatorId?: string;
  comment?: string;
}

export interface CrmCustomerCard {
  cardMask: string;
}

export interface CrmPlaceRegistration {
  issued?: string;
  country?: string;
  address?: string;
  addressEn?: string;
  issueDate?: string;
  subStreet?: string;
  subStreetEn?: string;
  street?: string;
  streetEn?: string;
  town?: string;
  townEn?: string;
  state?: string;
  stateId?: string;
}

export interface CrmCustomerDto {
  id: string;
  status: CrmCustomerStatus;
  cards: CrmCustomerCard[];
  email: string | null;
  phone: string;
  avatar: string | null;
  lastScoring: CrmCustomerScoring | null;
  passport: CrmCustomerPassport | null;
  drivingLicense: CrmCustomerDrivingLicense | null;
  placeRegistration: CrmPlaceRegistration | null;
  firstName: string | null;
  lastName: string | null;
  patronymic: string | null;
  birthDate: Date | null;
  registrationDate: Date;
  loginDate: Date | null;
}
