export interface DeliveryPointInterface {
  id: string;
  /** Адрес точки */
  fullAddress: string;
  /** Город */
  city: string;
  /** Расписание */
  schedule: string;
  /** Широта */
  latitude: number;
  /** Долгота */
  longitude: number;
  /** Радиус действия точки */
  deliveryRadius: number;
}
