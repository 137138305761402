import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { DriverModel } from './models';

/**
 * ### Линковка водителя к аренде
 * basePath: /crm
 * controller: /rent
 * path: ':rentId/link-driver'
 * method: POST
 * fullPath: /mobile-bff/rent/:rentId/link-driver
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MobileBffLinkDriverEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Rent;
  export const endPointPath = ':rentId/link-driver';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ
   */
  export type ResponseData = Omit<DriverModel, 'profile'>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    rentId: string;
  };

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {
    /** Идентификатор водителя */
    driverId: string;
    /** Никнейм */
    nickname?: string | null;
  };

  export enum ErrorCodes {}
}
