import { CrmRoleDto } from './crm-role.dto';

export interface CrmUserDto {
  id: string;
  active: boolean;
  login: string;
  role: CrmRoleDto;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  patronymic: string | null;
  loginDate: Date | null;
  createdAt: Date;
  updatedAt: Date | null;
}
