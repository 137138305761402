export interface AppointmentModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  /** Идентификатор аренды */
  readonly rentId: string;
  /** Идентификатор клиента */
  readonly customerId: string;
  /** Идентификатор авто */
  readonly vehicleId: string;
  /** VIN авто */
  vin: string;
  /** Номер телефоне пользователя */
  phone: string;
  /** Виды работ */
  reasons: AppointmentReason[];
  /** Статус заявки */
  status: AppointmentStatus;
  /** Желаемое время */
  preferDate: Date;
  /** Подтверждённое время */
  approvedDate: Date | null;
  /** Дата подверждения заявки */
  approveDate: Date | null;
  /** Дата закрытия заявки */
  closeDate: Date | null;
  /** Подробное описание обращения */
  description: string | null;
  /** Дата последнего изменения статуса */
  statusUpdatedDate: Date | null;
  /** Идентификатор актора последнего изменения */
  actorId: string | null;
}

export enum AppointmentReason {
  TechnicalService = 'TECHNICAL_SERVICE', // техобслуживание
  TireService = 'TIRE_SERVICE', // шиномонтаж
  Repair = 'REPAIR', // ремонт
}

export enum AppointmentStatus {
  New = 'NEW', // новая
  Cancelled = 'CANCELLED', // отмененная
  Approved = 'APPROVED', // подтверждённая
  Rejected = 'REJECTED', // отклонённая
  Completed = 'COMPLETED', // завершенная
}
