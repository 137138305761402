import { CommonModule } from "@angular/common";
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { MediaObserverModule } from "@app/core/media-observer/media-observer.module";
import { ru } from "date-fns/locale";

import { ApiModule } from "./api/api.module";
import { AuthModule } from "./auth/auth.module";
import { LocaleModule } from "./locale/locale.module";
import { LoginRestrictionModule } from "./login-restriction/login-restriction.module";
import { NavigationModule } from "./navigation/navigation.module";
import { ReloadControlModule } from "./reload-control/reload-control.module";
import { SidenavModule } from "./sidenav/sidenav.module";
import { SnackBarModule } from "./snack-bar/snack-bar.module";
import { SvgIconModule } from "./svg-icon/svg-icon.module";
import { UpdaterModule } from "./updater/updater.module";

const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: "outline",
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SvgIconModule,
    AuthModule,
    ApiModule,
    UpdaterModule,
    ReloadControlModule,
    SnackBarModule,
    SidenavModule,
    LocaleModule,
    NavigationModule,
    LoginRestrictionModule,
    MediaObserverModule.forRoot({
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "ru" },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "RUB",
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: ru,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_OPTIONS,
    },
  ],
})
export class CoreModule {}
