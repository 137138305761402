import { EngineType, VehicleDriveType, VehicleTransmissionType } from '../../vehicle/dto';
import { TariffDurationUnit } from '../../rent';

export interface MobileBffGetCatalogResponseDto {
  id: string;
  vehicleId: string;
  renderPath: string | null;
  model: string;
  brand: string;
  year: number;
  tariffId: string;
  price: number;
  isBooked: boolean;
  engineType: EngineType | null;
  enginePower: number | null;
  duration: number;
  durationUnit: TariffDurationUnit;
  transmissionType: VehicleTransmissionType | null;
  driveType: VehicleDriveType | null;
}
