import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { GetCatalogRequestDto, GetLastPacketResponseDto, VehicleInterface } from './dto';

/**
 * ### Получение каталога
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: 'catalog'
 * method: POST
 * fullPath: /mobile-bff/vehicle/catalog
 */
export namespace MobileBffGetCatalogEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = 'catalog';
  export const getUrl = (): string => joinPath([servicePath, controller, endPointPath], null, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: каталог авто
   */
  export type ResponseData = PaginationInterface<VehicleInterface>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {};

  /** Параметры запроса */
  export type RequestQueryParams = PaginationRequest;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса */
  export type RequestBody = GetCatalogRequestDto;

  export enum ErrorCodes {}
}
