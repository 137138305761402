import { VehicleModificationInterface } from '../../vehicle/dto';

export interface MobileBffGetCatalogItemResponseDto {
  vehicleId: string;
  renderPath: string | null;
  model: string;
  brand: string;
  year: number;
  tariffId: string;
  price: number;
  modification: VehicleModificationInterface;
  description: string | null;
  images: string[];
  ident: string;
  name: string;
  duration: number;
  mileageSessionLimit: number | null;
  mileageTotalLimit: number | null;
  mileageCost: number | null;
  hoursSessionLimit: number | null;
  hoursTotalLimit: number | null;
  hoursCost: number | null;
}
