import { RentModel } from '../../../mobile-bff';

export interface CrmGetRentResponseDto extends RentModel {
  timeline: CrmRentTimelineItemDto[];
}

export interface CrmRentTimelineItemDto {
  title: string;
  date?: Date | null;
  color: string;
  checkColor?: string;
  started?: boolean;
  finished?: boolean;
}
