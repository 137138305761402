import { CheckListEntity } from './check-list.entity';
import { CheckListItemEntity } from './check-list-item.entity';
import { CheckListStageEntity } from './check-list-stage.entity';

export enum CheckListTaskStatus {
  New = 'NEW',
  Processing = 'PROCESSING',
  Done = 'DONE',
  Problem = 'PROBLEM',
  Solved = 'SOLVED',
}

export interface VehicleInfo {
  vin: string;
  brand: string;
  model: string;
  license: string | null;
}

export type CheckListTaskEntity = {
  /**
   * Идентификатор задачи
   */
  id: string;
  /**
   * Идентификатор чеклиста
   */
  listId: CheckListEntity['id'];
  /**
   * Дата создания
   */
  createdAt: Date;
  /**
   * Идентификатор автомобиля
   */
  vehicleId: string;
  /**
   * Результат задачи
   */
  result: Record<CheckListStageEntity['code'], Record<CheckListItemEntity['code'], string | number | boolean>> | null;
  /**
   * Идентификатор техника
   */
  technicId: string | null;
  /**
   * Статус задачи
   */
  status: CheckListTaskStatus;
  /**
   * Комментарий проблемы
   */
  comment: string | null;
  /**
   * Информация автомобиля
   */
  vehicle: VehicleInfo | Record<string, never>;
};
