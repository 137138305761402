import { CheckListStageEntity } from './check-list-stage.entity';

export enum CheckListItemType {
  Photo = 'photo',
  CheckBox = 'checkbox',
  Text = 'text',
}

export type CheckListItemEntity = {
  id: string;
  /**
   * Идентификатор этапа
   */
  stageId: CheckListStageEntity['id'];
  /**
   * Название поле
   */
  name: string;
  /**
   * Тип поля
   */
  type: CheckListItemType;
  /**
   * Код поля
   */
  code: string;
};
