export enum CommandType {
  BlockEngine = 'OUTON', // Заблокировать двигатель
  UnblockEngine = 'OUTOFF', // Отменить блокировку двигателя
  OpenDoors = 'GRDOFF', // Открыть двери
  CloseDoors = 'GRDON', // Закрыть двери
  SetGuard = 'SETGUARD', // Включить режим охраны
  ClearGuard = 'CLRGUARD', // Отключить режим охраны
  StartEngine = 'ENGSTART', // Запуск двигателя
  StopEngine = 'ENGSTOP', // Остановка двигателя в режиме автозапуска
  Avar = 'AVAR', //  единая команда управления “аварийкой”
  AvarOpen = 'AVAROPEN', // используется при открытии автомобиля
  AvarClose = 'AVARCLOSE', // используется при закрытии автомобиля
  CloseWindows = 'OKNA', // Закрытие окон
  OpenBag = 'OPENBAG', // Открытие багажника
  Beep = 'BEEP', // Гудок
}
