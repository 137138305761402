import {
  CrmVehicleColor,
  CrmVehicleDimensions,
  CrmVehicleEquipment,
  CrmVehicleImage,
  CrmVehicleInterface,
  CrmVehicleModification,
} from '../interfaces';

type CreateVehicleRequiredFields = Pick<CrmVehicleInterface, 'vin' | 'brand' | 'model'>;
type CreateVehicleOptionalFields = Partial<
  Pick<
    CrmVehicleInterface,
    | 'license'
    | 'vehiclePassport'
    | 'electronicPassport'
    | 'issueYear'
    | 'manufactureCountry'
    | 'description'
    | 'bodyType'
    | 'doorCount'
    | 'seats'
    | 'steeringWheelPlacement'
  > & {
    telemetryBlocks?: string[];
    color?: Partial<CrmVehicleColor>;
    modification?: Partial<CrmVehicleModification>;
    equipment?: Partial<CrmVehicleEquipment>;
    dimensions?: Partial<CrmVehicleDimensions>;
    images?: CrmVehicleImage[];
  }
>;

export type CrmVehiclePostRequestDto = CreateVehicleRequiredFields & CreateVehicleOptionalFields;
