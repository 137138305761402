export interface InsuranceModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  vehicleId: string;
  docNumber: string;
  startDate: Date;
  endDate: Date;
  type: InsuranceType;
}

export enum InsuranceType {
  Kasko = 'KASKO',
  Osago = 'OSAGO',
}
