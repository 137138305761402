import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { LogGroup, RentLogEventType } from '../../mobile-bff';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmFindRentLogsResponseDto } from './dtos';

/**
 * ### Получение списка событий аренды
 * basePath: /crm
 * controller: /rent
 * path: 'logs'
 * method: GET
 * fullPath: /crm/rent/logs
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmFindRentLogsEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Rent;
  export const endPointPath = 'logs';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<CrmFindRentLogsResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: 'id';
    sortDirection?: 'asc' | 'desc';

    rentId?: string;
    eventType?: RentLogEventType;
    group?: LogGroup;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
