import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { VehicleInterface } from './dto';

/**
 * ### Поиск машины из каталога
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: 'catalog-find-vehicles'
 * method: GET
 * fullPath: /mobile-bff/vehicle/catalog-find-vehicles'
 */
export namespace MobileBffFindCarsFromCatalogEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = 'catalog-find-vehicles';
  export const getUrl = (): string => joinPath([servicePath, controller, endPointPath], null, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: авто из каталога
   */
  export type ResponseData = PaginationInterface<VehicleInterface>;
  export type Response = ResponseType<ResponseData>;

  /** Параметры пути: отсутствуют */
  export type RequestPathParams = {};

  /** Параметры запроса
   * @param isNotReserved
   * @param isReserved
   * */
  export interface RequestQueryParams extends PaginationRequest {
    isNotReserved?: true;
    isReserved?: true;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
