import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { VehicleInterface } from './dto';

/**
 * ### Получение по vehicle id
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: 'by-vehicle-id'
 * method: GET
 * fullPath: /mobile-bff/vehicle/by-vehicle-id
 */
export namespace MobileBffGetVehicleByVehicleIdEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = 'by-vehicle-id';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: сущность авто
   */
  export type ResponseData = VehicleInterface;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {};

  /** Параметры запроса
   * @param vehicleId vehicleId
   * */
  export type RequestQueryParams = {
    vehicleId: string;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
