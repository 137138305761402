import { EDeviceType } from '../../../mobile-bff/vehicle/dto';

export interface DeviceDataInterface {
  /** идентификатор */
  id: string;
  /** идентификатор устройства */
  deviceId: string;

  /** идентификатор авто */
  vehicleId: string | null;
  /** является приоритетным */
  priority: boolean;

  /** */
  activationStatus: boolean;
  /** */
  deviceSpecific: DeviceSpecificData;
  /** */
  installed: boolean;
  /** */
  integrity: boolean;

  // /** */
  // processType: EDeviceProcessType | null;
  /** */
  firstActivationDate: Date | null;
  /** */
  activationDate: Date | null;
  /** */
  deactivationDate: Date | null;

  /** Дата создания */
  createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
}

export interface DeviceInterface extends DeviceDataInterface {
  /** тип устройства */
  type: EDeviceType;
}

export enum EDeviceProcessType {
  ACTIVATION = 'ACTIVATION',
  DEACTIVATION = 'DEACTIVATION',
  UPDATING = 'UPDATING',
  TESTING = 'TESTING',
  INSTALLATION = 'INSTALLATION',
}

export enum EMisosModel {
  Lite = 'LITE',
  Pro = 'PRO',
}

/**
 * Данные устройства Misos
 */
export interface MisosData {
  /** id установленных сим карт */
  simCardsIds: string[];

  /** Модель */
  model: EMisosModel;

  /** imei */
  imei: string;

  /** Серийный номер */
  serial: string | null;
}

export type DeviceSpecificData = MisosData;
export { EDeviceType } from '../../../mobile-bff/vehicle/dto';
