import { DeviceInterface } from '../interfaces';

export type CreateDeviceRequiredFields = Pick<
  DeviceInterface,
  'deviceId' | 'activationStatus' | 'type' | 'deviceSpecific'
>;
export type CreateDeviceOptionalFields = Partial<
  Pick<DeviceInterface, 'firstActivationDate' | 'activationDate' | 'deactivationDate'>
>;

export type CrmDeviceMisosPostRequestDto = CreateDeviceRequiredFields & CreateDeviceOptionalFields;
