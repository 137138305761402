import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmGetRentDriversResponseDto } from './dtos/crm-get-rent-drivers.response.dto';

/**
 * ### Получение водителей аренды
 * basePath: /crm
 * controller: /rent
 * path: ':rentId/drivers'
 * method: GET
 * fullPath: /crm/rent/:rentId/drivers
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmGetRentDriversEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Rent;
  export const endPointPath = ':rentId/drivers';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = CrmGetRentDriversResponseDto;
  export type Response = ResponseType<ResponseData>;

  /** Параметры пути */
  export type RequestPathParams = {
    /** Идентификатор аренды */
    rentId: string;
  };

  /** Параметры запроса */
  export interface RequestQueryParams {
    /** Только активные водители */
    onlyActive?: boolean;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
