export enum EngineType {
  /** Электро */
  Electronic = 'ELECTRONIC',
  /** Топливный */
  Fuel = 'FUEL',
}

export interface VehicleModificationInterface {
  /** Название */
  name: string | null;
  /** Код */
  modificationCode: string | null;
  /** Мощность */
  power: number | null;
  /** Ёмкость батареи или бака */
  batteryOrTankCapacity: number | null;
  /** Запас хода */
  powerReserve: number | null;
  /** Максимальная скорость */
  maxSpeed: number | null;
  /** Время разгона до 100 кмч */
  accelerationTime100: number | null;
  /** Время разгона до 60 кмч */
  accelerationTime60: number | null;
  /** Максимальная крутящий момент на оборотах */
  maxTorqueAtRpm: number | null;
  /** Тип передних тормозов*/
  frontBrakes: string | null;
  /** Тип задних тормозов*/
  backBrakes: string | null;
  /** тип подвески */
  suspensionType: string | null;
  /** тип двигателя */
  engineType: EngineType | null;
  /** тип топлива */
  fuelType: string | null;
  /** мощность двигателя KWt/h */
  enginePower: number | null;
  /** тип трансмиссии */
  transmissionType: VehicleTransmissionType | null;
  /** тип привода */
  driveType: VehicleDriveType | null;
  /** обьём двигателя */
  engineCapacity: number | null;
}

export enum VehicleDriveType {
  AWD = 'AWD', // all-wheel-drive
  FWD = 'FWD', // front wheel drive
  RWD = 'RWD', // rear wheel drive
}

export enum VehicleTransmissionType {
  Manual = 'MANUAL',
  Automatic = 'AUTOMATIC',
}

export interface VehicleImage {
  /** Адрес изображения */
  url: string;
  /** Идентификатор изображения */
  imageId: string;
}

export interface VehicleGenerationInterface {
  name: string | null;
}

export interface VehicleEquipmentInterface {
  equipmentCode: string | null;
  name: string | null;
}

export interface VehicleColorInterface {
  color: string | null;
  colorCode: string | null;
}

export declare enum EDeviceType {
  MISOS = 'MISOS',
  BLUELINK = 'BLUELINK',
}
export interface VehicleBlockInterface {
  /** id блока */
  id: string;
  /** тип */
  type: EDeviceType;
  /** блок является приоритетным */
  priority: boolean;
  /** Модель блока */
  model: string | 'LITE' | 'PRO';
  /** Статус активации блока */
  active: boolean;
  /** Дата первой активации */
  firstActivationDate: Date | null;
  /** Дата последней активации */
  activationDate: Date | null;
  /** Дата последней деактивации */
  deactivationDate: Date | null;
}

export interface VehicleInterface {
  /** id машины */
  id: string;
  /** vin код авто */
  vin: string;
  /** альтернативный vin машины */
  vin2: string | null;
  /** Гос номер*/
  license: string | null;
  /** OCN код */
  ocn: string | null;
  /** сервис, которому принадлежит авто */
  currentService: string | null;
  /** марка */
  brand: string;
  /** модель */
  model: string;
  /** описание цвета */
  color: VehicleColorInterface;
  /** год выпуска */
  issueYear: string | null;
  /** данные о модификации */
  modification: VehicleModificationInterface;
  /** данные о комплектации */
  equipment: VehicleEquipmentInterface;
  /** данные об интерьере */
  interior: {};
  /** Данные о поколении */
  generation: VehicleGenerationInterface;
  /** ПТС */
  vehiclePassport: string | null;
  /** у авто есть электронный птс */
  electronicVehiclePassport: boolean;
  /** количество дверей */
  doorCount: number | null;
  /** Изображения авто */
  images: VehicleImage[];
  /** Медиаконтент по авто */
  mediaContent: {};
  /** Идентификаторы блоков телематики */
  telemetryBlocks: string[];
  /** Дополнительное оборудование */
  accessories: {}[];
  /** Блоки телематики */
  blocks: VehicleBlockInterface[];
  /** Расположение руля*/
  steeringWheelPlacement: string | null;
  /** Тип кузова*/
  bodyType: string | null;
  /** Страна производства */
  manufactureCountry: string | null;
}
