export interface DocumentModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  rentId: string;
  sessionId: string | null;
  customerId: string;
  name: string;
  type: DocumentType;
  url: string | null;
  path: string | null;
  status: DocumentStatus;
  signedDate: Date | null;
  payload: DocumentPayload | null;
  version: number;
}

export enum DocumentType {
  RentContract = 'RENT_CONTRACT',
  VehicleCheckContract = 'VEHICLE_CHECK_CONTRACT',
  Attorney = 'ATTORNEY_DOC',
}

export enum DocumentStatus {
  New = 'NEW',
  Generating = 'GENERATING',
  SignatureWaiting = 'SIGNATURE_WAITING',
  Signed = 'SIGNED',
  Outdated = 'OUTDATED',
}

export interface RentContractPayload {
  rentId: string;
  //
}

export interface VehicleCheckContractPayload {
  rentId: string;
  //
}

export type DocumentPayload = RentContractPayload | VehicleCheckContractPayload;
