import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { CommandType } from './enum/commands-vehicle.enum';
import { GetLastPacketResponseDto } from './dto';

/**
 * ### Отправка команды
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: ':vin/command'
 * method: POST
 * fullPath: /mobile-bff/vehicle/:vin/command
 */
export namespace MobileBffSendCommandEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = ':vin/command';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: ответ от телематики
   */
  export type ResponseData = GetLastPacketResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * @param vin вин авто
   * */
  export type RequestPathParams = {
    vin: string;
  };

  /** Параметры запроса
   * @param command команда
   * */
  export type RequestQueryParams = {
    command: CommandType;
  };

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
