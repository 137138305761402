import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { AddCarToCatalogRequestDto, AddCarToCatalogResponseDto, VehicleInterface } from './dto';

/**
 * ### Добавление машины в каталог
 * basePath: /mobile-bff
 * controller: /vehicle
 * path: 'add-car-to-catalog'
 * method: POST
 * fullPath: /mobile-bff/vehicle/add-car-to-catalog'
 */
export namespace MobileBffAddCarToCatalogEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Vehicle;
  export const endPointPath = 'add-car-to-catalog';
  export const getUrl = (): string => joinPath([servicePath, controller, endPointPath], null, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: ответ создания авто в каталоге
   */
  export type ResponseData = AddCarToCatalogResponseDto;
  export type Response = ResponseType<ResponseData>;

  /** Параметры пути: отсутствуют */
  export type RequestPathParams = {};

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса */
  export type RequestBody = AddCarToCatalogRequestDto;

  export enum ErrorCodes {}
}
