export interface RentLogModel<T = object | null> {
  readonly id: string;
  eventDate: Date;
  eventType: RentLogEventType;
  eventData: T;
  rentId: string;
  sessionId: string | null;
  actorId: string | null;
  actorType: string;
  group: LogGroup;
}

export enum LogGroup {
  Rent = 'RENT',
  Driver = 'DRIVER',
}
export enum RentLogEventType {
  ReservationCreated = 'RESERVATION_CREATED',
  ReservationCanceled = 'RESERVATION_CANCELED',
  PreReservationExpired = 'PRE_RESERVATION_EXPIRED',
  Reserved = 'RESERVED',

  RentUpdated = 'RENT_UPDATED',
  RentCancelled = 'RENT_CANCELLED',
  RentCompleted = 'RENT_COMPLETED',
  RentClosed = 'RENT_CLOSED',

  RentDriverAdded = 'RENT_DRIVER_ADDED',
  RentDriverRemoved = 'RENT_DRIVER_REMOVED',

  ReservationOrderCreated = 'RESERVATION_ORDER_CREATED',
  ReservationOrderRegister = 'RESERVATION_ORDER_REGISTER',
  ReservationOrderAuthorized = 'RESERVATION_ORDER_AUTHORIZED',
  ReservationOrderCompleted = 'RESERVATION_ORDER_COMPLETED',
  ReservationOrderFailed = 'RESERVATION_ORDER_FAILED',

  OrderExpired = 'ORDER_EXPIRED',

  VehicleCheckWaitingStarted = 'VEHICLE_CHECK_WAITING_STARTED',
  VehicleCheckStarted = 'VEHICLE_CHECK_STARTED',
  VehicleCheckFinished = 'VEHICLE_CHECK_FINISHED',
  VehicleCheckCanceled = 'VEHICLE_CHECK_CANCELED',

  VehicleDoorOpened = 'VEHICLE_DOOR_OPENED',
  VehicleEngineUnblocked = 'VEHICLE_ENGINE_UNBLOCKED',

  RentContractGenerated = 'RENT_CONTRACT_GENERATED',
  RentContractSigned = 'RENT_CONTRACT_SIGNED',
  VehicleCheckContractGenerated = 'VEHICLE_CHECK_CONTRACT_GENERATED',
  VehicleCheckContractSigned = 'VEHICLE_CHECK_CONTRACT_SIGNED',
}
