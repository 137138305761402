export interface PaginationRequest {
  /** Запрошенная страница */
  page: number;
  /** Размер страницы */
  onPage: number;
}

export interface PaginationInterface<T> extends PaginationRequest {
  /** Полезные данные */
  rows: T[];
  /** @deprecated Общее количество записей для запроса */
  totalCount?: number;
  /** Общее количество записей для запроса */
  total: number;
}
