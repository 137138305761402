import { TariffModel } from './tariff-model';

export interface DeviceInfoModel {
  mileage: number;
  ignition: boolean;
  isDoorsOpened: boolean;
  isEngineBlocked: boolean;
  lat: number;
  lon: number;
}

export interface SessionModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  rentId: string;
  vehicleId: string;
  customerId: string;
  orderId: string | null;
  startDate: Date;
  endDate: Date;
  status: SessionStatus;
  price: number;
  paid: boolean;
  startDeviceInfo: DeviceInfoModel | null;
  endDeviceInfo: DeviceInfoModel | null;

  tariff: TariffModel;
}

export enum SessionStatus {
  New = 'NEW',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Done = 'DONE',
}
