export interface CatalogVehicleModel {
  id: string;
  vehicleId: string;
  renderPath: string | null;
  model: string;
  brand: string;
  year: number;
  tariffId: string;
  price: number;
  isBooked: boolean;
}
