import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmVehicleGetResponseDto } from './dtos';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Получения списка машин
 * basePath: /crm
 * controller: /vehicle
 * path:
 * method: GET
 * fullPath: /crm/vehicle/
 */
export namespace CrmVehicleFindEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список машин
   */
  export type ResponseData = PaginationInterface<CrmVehicleGetResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса:
   * * onPage - Размер страницы
   * * page - Запрошенная страница
   * */
  export interface RequestQueryParams
    extends Record<string, string | number | undefined | string[]>,
      PaginationRequest {
    ocn?: string;
    blockSerial?: string;
    simId?: string;
    imei?: string;
    vin?: string;
    vin2?: string;
  }

  /** Заголовки: без параметров */
  export interface RequestHeaders extends Record<string, string | number> {}

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
