import { CheckListEntity } from './check-list.entity';
import { CheckListItemEntity } from './check-list-item.entity';

export type CheckListStageEntity = {
  id: string;
  /**
   * Идентификатор чеклиста
   */
  listId: CheckListEntity['id'];
  /**
   * Название этапа
   */
  name: string;
  /**
   * Очередность этапа
   */
  order: number | null;
  /**
   * Код этапа
   */
  code: string;
  /**
   * Обязательность этапа
   */
  required: boolean;
  /**
   * Поля этапа
   */
  items: CheckListItemEntity[];
};
