import { CustomerModel } from './customer.model';
import { DocumentModel } from './document.model';
import { SessionModel } from './session.model';
import { TariffModel } from './tariff-model';
import { TransportParkModel } from './transport-park.model';
import { VehicleModel } from './vehicle.model';

export interface RentModel {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  vehicleId: string;
  customerId: string;
  tariffId: string;
  status: RentStatus;
  startDate: Date;
  endDate: Date | null;
  plannedEndDate: Date;
  closeDate: Date | null;
  price: number;
  isOwn: boolean;
  issuePointId: string;
  deliveryPointId: string | null;

  issueTransportPark: TransportParkModel | null;
  deliveryTransportPark: TransportParkModel | null;
  vehicle: VehicleModel | null;
  tariff: TariffModel;
  customer: CustomerModel;

  documents: DocumentModel[]; // relation
  sessions: SessionModel[]; // relation
}

export enum RentStatus {
  New = 'NEW', // новое бронирование
  PreReservationExpired = 'PRE_RESERVATION_EXPIRED', // вышел срок предбронирование
  PreReservationCanceled = 'PRE_RESERVATION_CANCELED', // предбронирование отменено клиентом
  Reserved = 'RESERVED', // забронировано, оплачен аванс
  ReservationCanceled = 'RESERVATION_CANCELED', // бронирование отменено клиентом
  VehicleCheckWaiting = 'VEHICLE_CHECK_WAITING', // ожидание приёмки авто
  VehicleCheck = 'VEHICLE_CHECK', // приёмка авто
  Active = 'ACTIVE', // активная аренда
  VehicleBlocked = 'VEHICLE_BLOCKED', // авто заблокированна
  Completed = 'COMPLETED', // успешно завершённая аренда
  Cancelled = 'CANCELLED', // досрочно завершённая аренда
  Closed = 'CLOSED', // закрытая аренда (финально завершённая)
}

export type RentModelLite = Omit<RentModel, 'documents' | 'sessions' | 'customer'>;
