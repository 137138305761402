export interface ProfileModel {
  /** Идентификатор клиента */
  customerId: string;
  /** Номер телефона */
  phone: string;
  /** Почта */
  email: string | null;
  /** Имя пользователя */
  firstName: string | null;
  /** Фамилия пользователя */
  lastName: string | null;
  /** Отчество пользователя */
  patronymic: string | null;
}
