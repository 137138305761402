export interface Card {
  id: string;
  cardMask: string;
  expireDate: string;
}

export interface MobileBffGetProfileResponseDto {
  phone: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  patronymic: string | null;
  status: string;
  cards: Card[];
}
